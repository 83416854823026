import request from '@/utils/request'

// 查询消息接收详情列表
export function listRecipient (query) {
  return request({
    url: '/message/recipient/list',
    method: 'get',
    params: query
  })
}

// 查询消息接收详情详细
export function getRecipient (id) {
  return request({
    url: '/message/recipient/' + id,
    method: 'get'
  })
}

// 新增消息接收详情
export function addRecipient (data) {
  return request({
    url: '/message/recipient',
    method: 'post',
    data: data
  })
}

// 修改消息接收详情
export function updateRecipient (data) {
  return request({
    url: '/message/recipient',
    method: 'put',
    data: data
  })
}

// 删除消息接收详情
export function delRecipient (id) {
  return request({
    url: '/message/recipient/' + id,
    method: 'delete'
  })
}

// 导出消息接收详情
export function exportRecipient (query) {
  return request({
    url: '/message/recipient/export',
    method: 'get',
    params: query
  })
}
