var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "消息通知ID", prop: "notificationId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息通知ID" },
                model: {
                  value: _vm.form.notificationId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "notificationId", $$v)
                  },
                  expression: "form.notificationId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "接收用户", prop: "userId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入接收用户" },
                model: {
                  value: _vm.form.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userId", $$v)
                  },
                  expression: "form.userId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "接收状态", prop: "statusReceive" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入接收状态" },
                model: {
                  value: _vm.form.statusReceive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "statusReceive", $$v)
                  },
                  expression: "form.statusReceive",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息应用编码", prop: "appCode" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息应用编码" },
                model: {
                  value: _vm.form.appCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appCode", $$v)
                  },
                  expression: "form.appCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户通信地址", prop: "userChannelAddress" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入用户通信地址" },
                model: {
                  value: _vm.form.userChannelAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userChannelAddress", $$v)
                  },
                  expression: "form.userChannelAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息链路id", prop: "traceId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入消息链路id" },
                model: {
                  value: _vm.form.traceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "traceId", $$v)
                  },
                  expression: "form.traceId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }