<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="消息通知ID" prop="notificationId" >
        <a-input v-model="form.notificationId" placeholder="请输入消息通知ID" />
      </a-form-model-item>
      <a-form-model-item label="接收用户" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入接收用户" />
      </a-form-model-item>
      <a-form-model-item label="接收状态" prop="statusReceive" >
        <a-input v-model="form.statusReceive" placeholder="请输入接收状态" />
      </a-form-model-item>
      <a-form-model-item label="消息应用编码" prop="appCode" >
        <a-input v-model="form.appCode" placeholder="请输入消息应用编码" />
      </a-form-model-item>
      <a-form-model-item label="用户通信地址" prop="userChannelAddress" >
        <a-input v-model="form.userChannelAddress" placeholder="请输入用户通信地址" />
      </a-form-model-item>
      <a-form-model-item label="消息链路id" prop="traceId" >
        <a-input v-model="form.traceId" placeholder="请输入消息链路id" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecipient, addRecipient, updateRecipient } from '@/api/message/recipient'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        notificationId: null,
        userId: null,
        statusReceive: null,
        appCode: null,
        userChannelAddress: null,
        createBy: null,
        traceId: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        notificationId: [
          { required: true, message: '消息通知ID不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '接收用户不能为空', trigger: 'blur' }
        ],
        statusReceive: [
          { required: true, message: '接收状态不能为空', trigger: 'blur' }
        ],
        appCode: [
          { required: true, message: '消息应用编码不能为空', trigger: 'blur' }
        ],
        userChannelAddress: [
          { required: true, message: '用户通信地址不能为空', trigger: 'blur' }
        ],
        traceId: [
          { required: true, message: '消息链路id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        notificationId: null,
        userId: null,
        statusReceive: null,
        appCode: null,
        userChannelAddress: null,
        createBy: null,
        traceId: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecipient(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRecipient(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addRecipient(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
